//Import Statements Start
import DialoguePopup from "../../helpers/DialoguePopup";
import React, { useState, useRef, useEffect, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import cardsService from "../../services/cards.services";
import BoLoader from "../../helpers/BoLoader";
import moment from "moment";
import DateFormat from "../../helpers/DateFormat";
import { subYears } from "date-fns";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import { useSelector } from "react-redux";
import GetAllClients from "../../helpers/GetAllClients";
import RefreshToken from "../../helpers/RefreshToken";
import ReactFlagsSelect from "react-flags-select";
import {TrimCharacters} from "../../helpers/TrimCharacters";
import GetAllSubClients from "../superclient/GetAllSubClients";
import IsEmptyObj from "../../helpers/IsEmpty";
import clientmanagmentObj from "../../services/client-managment.service";
import { haspermissions } from "../permissions/PermissionTypes";
//Import Statements End

//Validations Fields Input Start
const firstNameRequired = value => {
  if (!value) {
    document.getElementById("id_firstname").classList.add("has_error");
    return <span className="help-block">First Name is required!</span>;
  } else document.getElementById("id_firstname").classList.remove("has_error");
};
const firstNameLength = value => {
  if (value.length<3) {
    document.getElementById("id_firstname").classList.add("has_error");
    return <span className="help-block">First Name must contain 3 characters!</span>;
  } else document.getElementById("id_firstname").classList.remove("has_error");
};
const lastNameRequired = value => {
  if (!value) {
    document.getElementById("id_lastname").classList.add("has_error");
    return <span className="help-block">Last Name is required!</span>;
  } else document.getElementById("id_lastname").classList.remove("has_error");
};
const lastNameLength = value => {
  if (value.length<3) {
    document.getElementById("id_lastname").classList.add("has_error");
    return <span className="help-block">Last Name must contain 3 characters!</span>;
  } else document.getElementById("id_lastname").classList.remove("has_error");
};
const prefferedNameRequired = value => {
  if (!value) {
    document.getElementById("id_preferredname").classList.add("has_error");
    return <span className="help-block">Preferred Name is required!</span>;
  } else
    document.getElementById("id_preferredname").classList.remove("has_error");
};
const prefferedNameLength = value => {
  if (value.length<3) {
    document.getElementById("id_preferredname").classList.add("has_error");
    return <span className="help-block">Preferred Name must contain 3 characters!</span>;
  } else
    document.getElementById("id_preferredname").classList.remove("has_error");
};
const addressRequired = value => {
  if (!value) {
    document.getElementById("address").classList.add("has_error");
    return <span className="help-block"> Address is required!</span>;
  } else
    document.getElementById("address").classList.remove("has_error");
};
const addressLength = value => {
  if (value.length <8) {
    document.getElementById("address").classList.add("has_error");
    return <span className="help-block">Address must contain 8 characters!</span>;
  } else
    document.getElementById("address").classList.remove("has_error");
};
const cityRequired = value => {
  if (!value) {
    document.getElementById("city").classList.add("has_error");
    return <span className="help-block"> City is required!</span>;
  } else
    document.getElementById("city").classList.remove("has_error");
};
const cityLength = value => {
  if (value.length<3) {
    document.getElementById("city").classList.add("has_error");
    return <span className="help-block">City must contain 3 characters!</span>;
  } else document.getElementById("city").classList.remove("has_error");
};
const zipCodeRequired = value => {
  if (!value) {
    document.getElementById("zipcode").classList.add("has_error");
    return <span className="help-block"> Zip Code is required!</span>;
  } else
    document.getElementById("zipcode").classList.remove("has_error");
};
const zipCodeLength = value => {
  if (value.length<3) {
    document.getElementById("zipcode").classList.add("has_error");
    return <span className="help-block">Zip Code must contain 3 characters!</span>;
  } else document.getElementById("zipcode").classList.remove("has_error");
};
const proxyCodeRequired = value => {
  if (!value) {
    document.getElementById("id_cardproxy").classList.add("has_error");
    return <span className="help-block">Proxy Code is required!</span>;
  } else
    document.getElementById("id_cardproxy").classList.remove("has_error");
};
const cardProxyLength = value => {
  if (value.length<16) {
    document.getElementById("id_cardproxy").classList.add("has_error");
    return <span className="help-block">Proxy Code must contain 16 characters!</span>;
  } else document.getElementById("id_cardproxy").classList.remove("has_error");
};

const emailRequired = value => {
  if (!value) {
    document.getElementById("Email-frmgrp").classList.add("has_error");
    return <span className="help-block">Email is required!</span>;
  } else document.getElementById("Email-frmgrp").classList.remove("has_error");
};

const phonerequired = value => {
  if (!value) {
    document.getElementById("phone-frmgrp").classList.add("has_error");
    return <span className="help-block">Phone Number is required!</span>;
  } else document.getElementById("phone-frmgrp").classList.remove("has_error");
};
const validEmail = value => {
  if (!isEmail(value)) {
    document.getElementById("Email-frmgrp").classList.add("has_error");
    return <span className="help-block">Invalid Email!</span>;
  } else document.getElementById("Email-frmgrp").classList.remove("has_error");
};
const cardNumberRequired = value => {
  if (!value) {
    document.getElementById("id_cardnumber").classList.add("has_error");
    return <span className="help-block">This field is required!</span>;
  } else
    document.getElementById("id_cardnumber").classList.remove("has_error");
};
// const vusername = (value) => {
//   if (value.length < 3 || value.length > 50) {
//     return (
//       <span className="help-block">
//         The username must be between 2 and 50 characters.
//       </span>
//     );
//   }
// };
//Validations Fields Input End
//Starting point
const IssuePhysicalCardBody = props => {
  const { user: currentUser } = useSelector(state => state.auth);

  const [cardHolderDetailsAreaSelected, setCardHolderDetailsAreaSelected] =
    useState("true");
  const [cardDetailsAreaSelected, setCardDetailsAreaSelected] =
    useState("false");
  const [cardHolderDetailsClassName, setTcardHolderDetailsClassName] =
    useState("nav-link active");
  const [cardDetailsClassName, setTcardDetailsClassName] = useState("nav-link");
  const [cardHolderContentClassName, setCardHolderContentClassName] = useState(
    "tab-pane fade show active"
  );
  const [cardContentClassName, setCardContentClassName] =
    useState("tab-pane fade");
  const [modalShow, setModalShow] = React.useState(false);
  const form = useRef();
  const checkBtn = useRef();
  const [username, setUsername] = useState("");
  // const [Name, setBoUserName] = useState("");
  // const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dob, setDob] = useState();
  const [Address, setAddress] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [availableBalance, setAvailableBalance] = useState("");
  const [modelhdng, setMdlHdng] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [cardId, setCardId] = useState("");
  const [disableBtns, setDisabledBtns] = useState(false);
  const [showDOBErr, setShowDOBErr] = useState("d-none");
  const [showPhoneErr, setshowPhoneErr] = useState("d-none");
  const [boloader, setBoLoader] = useState(false);
  const [ClientNameVal, setClientNameVal] = useState("");
  const [isValidPhoneNumber, setisValidPhoneNumber] = useState(true);
  //New Fields for Cardholder and Cards Nium
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [PreferredName, setPreferredName] = useState("");
  const [Gender, setGender] = useState("Male");
  const [DateOfBirth, setDateOfBirth] = useState();
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Nationality, setNationality] = useState("");
  const [DeliveryCountry, setDeliveryCountry] = useState("");
  const [CountryCode, setCountryCode] = useState("");
  const [DeliveryAddress1, setDeliveryAddress1] = useState("");
  const [DeliveryCity, setDeliveryCity] = useState("");
  const [DeliveryZipCode, setDeliveryZipCode] = useState("");
  const [CardProxyNumber, setCardProxyNumber] = useState("");
  const [CardCvv, setCardCvv] = useState("");
  const [cardType, setcardType] = useState("GPR_VIR");
  const [cardIssuanceAction, setcardIssuanceAction] = useState("NEW");
  const [showNationalityErr, setshowNationalityErr] = useState("d-none");
  const [showDeliveryCountryErr, setshowDeliveryCountryErr] =
    useState("d-none");
  //Mswipe Payment Processor

  const [IssuerCategory, setIssuerCategory] = useState();
  const [CardNumber, setCardNumber] = useState("");
  const [State, setState] = useState("");
  
  const [CardColor, setCardColor] = useState("Green");
  const [showCardColorErr, setShowCardColorErr] =
  useState("d-none");
  const [isSuperClient, setIsSuperClient] = useState(false);
  const [showClientErr, setShowClientErr] = useState("d-none");
  const [ClientId, setClientId] = useState(currentUser?.user?.ClientId ?? 0);
  const [cardFeeCurrencyCode, setcardFeeCurrencyCode] = useState("");
  if (currentUser) {
    haspermissions.PhysicalCardCvv = currentUser?.user?.ClientPermissions?.includes("PhysicalCard CVV");
  }
  //Validations Dropdown Starts
  const requiredDob = value => {
    if (!value) {
      document.getElementById("dob-frmgrp").classList.add("has_error");
      setShowDOBErr("");
      return false;
    } else {
      document.getElementById("dob-frmgrp").classList.remove("has_error");
      setShowDOBErr("d-none");
      return true;
    }
  };
  const requiredPhoneNo = value => {
    if (phoneNumber.length < 3) setisValidPhoneNumber(false);
    else setisValidPhoneNumber(true);
    if (!value) {
      document.getElementById("phone-frmgrp").classList.add("has_error");
      setshowPhoneErr("");
      return false;
    } else {
      document.getElementById("phone-frmgrp").classList.remove("has_error");
      setshowPhoneErr("d-none");
      return true;
    }
  };
  const requiredNationality = value => {
    if (!value) {
      document.getElementById("id_nationality").classList.add("has_error");
      setshowNationalityErr("");
      return false;
    } else {
      document.getElementById("id_nationality").classList.remove("has_error");
      setshowNationalityErr("d-none");
      return true;
    }
  };
  const requiredDeliveryCountry = value => {
    if (!value) {
      document.getElementById("id_deliverycountry").classList.add("has_error");
      setshowDeliveryCountryErr("");
      return false;
    } else {
      document
        .getElementById("id_deliverycountry")
        .classList.remove("has_error");
      setshowDeliveryCountryErr("d-none");
      return true;
    }
  };
  //Validations Dropdown End
  const onChangeEmail = e => {
    const email = e.target.value;
    setEmail(email);
    setUsername(email);
  };
  const onChangeDOB = value => {
    let DOB = value;
    if (DOB) DOB = new Date(DOB);
    setDob(DOB);
    setDateOfBirth(DOB);
    //setDate(DOB);

    requiredDob(value);
  };
  const onChangePhoneNumber = e => {
    requiredPhoneNo(e);
    setPhoneNumber(e);
    setMobile(e);
  };
  const onChangeState = e => {
    let value = e.target.value;
    value=value.replace(/[^A-Za-z ]/ig, '')
    setState(value);
  };
  const onChangeCardNumber = e => {
    const name = e.target.value;
    setCardNumber(name);
  };
  const getSuperClientsDropDown = (clientid) => {
    setBoLoader(true);
    clientmanagmentObj.getAllClients()
      .then((response) => {
        setBoLoader(false);
        if (response.data.payload.succeeded) {
          let data = response.data.payload.result;
          const clientNameVal = data.map((options) => {
            if (options.id === clientid) {
              setIssuerCategory(options?.issuerCategory);
              return options;
            } 
          });
        }
      })
      .catch((err) => {
        setBoLoader(false);
      });
  };

  //UseEffects Start
  useEffect(() => {
    if(currentUser.user.SuperClientId>0){
      setIsSuperClient(true);
      
    }
    else{
      setIssuerCategory(currentUser.user.IssuerName)
    }
    
  }, []);
  useEffect(() => {
    if (phoneNumber.length === 0) setisValidPhoneNumber(true);
    else if (phoneNumber.length < 3) setisValidPhoneNumber(false);
    else setisValidPhoneNumber(true);
  }, [phoneNumber]);
  useEffect(() => {
    setCountryCode(Nationality);
  }, [Nationality]);

  useEffect(() => {
    let dateOfBirth = moment(DateOfBirth).format("YYYY-MM-DD");
    setDateOfBirth(dateOfBirth);
  }, [dob]);

  //UseEffects End
  //API Call Start
  const IssueCardHolderHandler = e => {
    if (e) e.preventDefault();
    form.current.validateAll();
    if(!isSuperClient)
    setClientId(currentUser.user.ClientId);
    let dobValid = requiredDob(dob);
    let phoneValid = requiredPhoneNo(phoneNumber);
    let nationality = requiredNationality(Nationality);
    let deliverycountry = requiredDeliveryCountry(DeliveryCountry);   
    if (
      checkBtn.current.context._errors.length === 0 &&
      dobValid &&
      phoneValid &&
      nationality &&
      deliverycountry
    ) {
      setBoLoader(true);

      cardsService
        .IssuePhysicalCardToCardHolder(
          FirstName,
          LastName,
          PreferredName,
          Nationality,
          CountryCode,
          Gender,
          Mobile,
          DateOfBirth,
          DeliveryAddress1,
          DeliveryCity,
          DeliveryZipCode,
          DeliveryCountry,
          Email,
          cardType,
          currentUser?.role.includes("Super client") ? cardFeeCurrencyCode: currentUser?.user?.ClientCurrency,
          Number(ClientId),
          CardProxyNumber,
          CardNumber,
          State,CardColor,CardCvv
        )
        .then(cardData => {
          setBoLoader(false);
          if (cardData && cardData.data && cardData.data.success) {
            setCardHolderDetailsAreaSelected("false");
            setCardDetailsAreaSelected("true");
            setTcardHolderDetailsClassName("nav-link");
            setTcardDetailsClassName("nav-link active");
            setCardHolderContentClassName("tab-pane fade");
            setCardContentClassName("tab-pane fade show active");
          }
        })
        .catch(err => {
          setBoLoader(false);
          let msgBody =
            err && err.response && err.response.data
              ? err.response.data.message
              : "Error Occured while Issuing card";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
          (async () => {
            setBoLoader(true);
            let resp = await RefreshToken(
              err && err.response && err.response.status
            );
            if (resp) {
              IssueCardHolderHandler();
              setBoLoader(false);
            } else {
              setBoLoader(false);
            }
          })();
        });
    }
  };
  //API Call End
  const errorAndSuccessModl = (heading, body, Icon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(Icon);
  };
  /////Note Please do not remove this code as this is used for back button, currently commented but will be uncommented in future/////
  // const goBackToCardHolderHandler = (e) => {
  //   setCardHolderDetailsAreaSelected("true");
  //   setCardDetailsAreaSelected("false");
  //   setTcardHolderDetailsClassName("nav-link active");
  //   setTcardDetailsClassName("nav-link");
  //   setCardHolderContentClassName("tab-pane fade show active");
  //   setCardContentClassName("tab-pane fade");
  // };
  const IssueCardDoneHandler = e => {
    e.preventDefault();
    props.onHide();
    
  };

  const onChangeFirstName = e => {
    let value = e.target.value;
    value=value.replace(/[^a-z0-9 ]/gi, '')
    setFirstName(value);
  };
  const onChangeLastName = e => {
    let value = e.target.value;
    value=value.replace(/[^a-z0-9 ]/gi, '')
    setLastName(value);
  };
  const onChangePreferredName = e => {
    let value = e.target.value;
    value=value.replace(/[^a-z0-9 ]/gi, '')
    setPreferredName(value);
  };
  const onChangeGender = e => {
    const name = e.target.value;
    setGender(name);
  };
  const onChangeDeliveryAddress = e => {
    const name = e.target.value;
    setDeliveryAddress1(name);
  };
  const onChangeDeliveryCity = e => {
    let value = e.target.value;
    value=value.replace(/[^A-Za-z ]/ig, '')
    setDeliveryCity(value);
  };
  const onChangeDeliveryZipCode = e => {
    let value = e.target.value;
    value=value.replace(/[^a-z0-9 ]/gi, '')
    setDeliveryZipCode(value);
  };
  const onChangeProxyCode = e => {
    const name = e.target.value;
    setCardProxyNumber(name);
  };
  const onChangeCardCvv = e => {
    const name = e.target.value;
    setCardCvv(name);
  };

  const maxLengthCheck = object => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const onChangeCardColor = e => {
    const name = e.target.value;
    setCardColor(name);
  };
    const ClientNameChangeHandlers = async e => {
    const clientName = e.target.selectedOptions[0].value;
    const currency = e?.target?.selectedOptions[0]?.attributes[0]?.nodeValue ?? "";
    if(!IsEmptyObj.IsEmpty(clientName)){
    let val =parseInt(clientName);
    getSuperClientsDropDown(val);
    setClientId(val);
    requiredClient(val);
    setcardFeeCurrencyCode(currency);
    }
  };
  const requiredClient = value => {
    if (!value) {
      document.getElementById("id_client").classList.add("has_error");
      setShowClientErr("");
      return false;
    } else {
      document.getElementById("id_client").classList.remove("has_error");
      setShowClientErr("d-none");
      return true;
    }
  };
  return (
    <Fragment>
      <div className="row">
        <BoLoader loading={boloader} />
        <div className="col-md-12">
          <h2>Activate Physical Card</h2>
          <div className="mt-16">
            <div className="row">
              <div className="col-md-12">
                <div className="bo-tabs-mbl">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={cardHolderDetailsClassName} //""
                        id="cardHolder-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#cardHolder"
                        type="button"
                        role="tab"
                        aria-controls="cardHolder"
                        aria-selected={cardHolderDetailsAreaSelected}
                      >
                        Cardholder Details
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={cardDetailsClassName}
                        id="cardDetails-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#cardDetails"
                        type="button"
                        role="tab"
                        aria-controls="cardDetails"
                        aria-selected={cardDetailsAreaSelected}
                      >
                        Card Details
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="tab-content" id="myTabContent">
                  <div
                    className={cardHolderContentClassName}
                    id="cardHolder"
                    role="tabpanel"
                    aria-labelledby="cardHolder-tab"
                  >
                    <div>
                      <h3>Enter cardholder details</h3>
                      <Form onSubmit={(e)=>{e.preventDefault()}} ref={form}>
                        <div className="row mt-16">
                        {isSuperClient ?(
                  <div className="col-md-6" id="id_client">
                            <div className="form-group">
                              <label>Select Program</label>
                              <div className="form-controls">
                                <GetAllSubClients
                                  clientnamechangehandler={
                                    ClientNameChangeHandlers
                                  }
                                  searchgriddata="Y"
                                />
                                <span
                                  className={
                                    "help-block " + showClientErr
                                  }
                                >
                                  Client is required!
                                </span>
                              </div>
                            </div>
                          </div>
                ):( <div className="col-md-6">
                <div className="form-group">
                    <label>Client Ledger</label>
                    <div className="form-controls">
                      <input 
                      type="text"
                      value={currentUser.user?.ClientName}
                      disabled={true}
                      />
                    </div>
                  </div>
                  </div>)}
              
                         
                            <div/>
                        
                          <div className="col-md-6">
                            <div className="form-group" id="id_firstname">
                              <label>First Name*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="cardholderName"
                                  value={FirstName}
                                  onChange={onChangeFirstName}
                                  onKeyDown={(evt) => {let chck=TrimCharacters.checkSpecialCharacters(evt.key)
                                    if(chck)
                                    evt.preventDefault();
                                    }
                                     }
                                  validations={[firstNameRequired,firstNameLength]}
                                  maxLength="20"
                                />
                                <span className="icon-user _ico"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="id_lastname">
                              <label>Last Name*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="LastName"
                                  value={LastName}
                                  onChange={onChangeLastName}
                                  onKeyDown={(evt) => {let chck=TrimCharacters.checkSpecialCharacters(evt.key)
                                    if(chck)
                                    evt.preventDefault();
                                    }
                                     }
                                  validations={[lastNameRequired,lastNameLength]}
                                  maxLength="20"
                                />
                                <span className="icon-user _ico"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="id_preferredname">
                              <label>Preferred Name*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="PreferredName"
                                  value={PreferredName}
                                  onChange={onChangePreferredName}
                                  onKeyDown={(evt) => {let chck=TrimCharacters.checkSpecialCharacters(evt.key)
                                    if(chck)
                                    evt.preventDefault();
                                    }
                                     }
                                  validations={[prefferedNameRequired,prefferedNameLength]}
                                  maxLength="20"
                                />
                                <span className="icon-user _ico"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Gender*</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  onChange={onChangeGender}
                                >
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3" id="dob-frmgrp">
                              <label>Date Of Birth* </label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={dob}
                                  onchangedate={onChangeDOB}
                                  maximumdate={subYears(new Date(), 18)}
                                />
                                <span className="icon-calender _ico"></span>
                                <span className="icon-round icon-calender-mini"></span>
                                <span className="fs-12 clr-submarine mt-2 d-block">
                                  Age should be greater than 18 years
                                </span>
                                {/* <span className="icon-round icon-calender-mini"></span> */}
                                <span className={"help-block " + showDOBErr}>
                                  DOB is required!
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="Email-frmgrp">
                              <label>Email*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="email"
                                  value={Email}
                                  onChange={onChangeEmail}
                                  validations={[emailRequired, validEmail]}
                                  maxLength="50"
                                />
                                <span className="icon-email _ico"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="phone-frmgrp">
                              <label>Phone Number*</label>
                              <div className="form-controls">
                                <PhoneInput
                                  class="form-control "
                                  country={"gb"}
                                  onChange={onChangePhoneNumber}
                                  value={phoneNumber}
                                  validations={phonerequired}
                                />
                                <span className={"help-block " + showPhoneErr}>
                                  Phone No is required!
                                </span>
                                {!isValidPhoneNumber && (
                                  <span className="help-block has_error">
                                    Minimum three number are required
                                  </span>
                                )}
                                {/* <span className="icon-phone _ico"></span> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="id_nationality">
                              <label>Nationality*</label>
                              <div className="form-controls">
                                <ReactFlagsSelect
                                  className="form-control no-icon"
                                  selected={Nationality}
                                  onSelect={code => {
                                    setNationality(code);
                                    requiredNationality(code);
                                  }}
                                  searchable={true}
                                />
                                <span
                                  className={"help-block " + showNationalityErr}
                                >
                                  Nationality is required!
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group" id="address">
                              <label> Address*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="address"
                                  value={DeliveryAddress1}
                                  onChange={onChangeDeliveryAddress}
                                  onKeyDown={(evt) => {let chck=TrimCharacters.checkSpecialCharacters(evt.key)
                                    if(chck)
                                    evt.preventDefault();
                                    }
                                     }
                                  validations={[addressRequired,addressLength]}
                                  maxLength="40"
                                />
                                <span className="icon-home _ico"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="id_deliverycountry">
                              <label> Country*</label>
                              <div className="form-controls">
                                <ReactFlagsSelect
                                  className="form-control no-icon"
                                  selected={DeliveryCountry}
                                  onSelect={code => {
                                    setDeliveryCountry(code);
                                    requiredDeliveryCountry(code);
                                  }}
                                  searchable={true}
                                />
                                <span
                                  className={
                                    "help-block " + showDeliveryCountryErr
                                  }
                                >
                                   Country is required!
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="city">
                              <label> City*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="address"
                                  value={DeliveryCity}
                                  onChange={onChangeDeliveryCity}
                                  onKeyDown={(evt) => {let chck=TrimCharacters.checkAlpha(evt.key)
                                    if(chck)
                                    evt.preventDefault();
                                    }
                                     }
                                  validations={[cityRequired,cityLength]}
                                  maxLength="20"
                                />
                              </div>
                            </div>
                          </div>
                          {IssuerCategory==="IssuerB4B"|| IssuerCategory === "IssuerG" ?(
                            <div className="col-md-6">
                            <div className="form-group" id="state">
                            {
  IssuerCategory === "IssuerB4B" &&
  (currentUser?.user?.ClientCurrency?.includes("EUR") || currentUser?.user?.ClientCurrency?.includes("GBP")) ? (
    <label>2 Digit Country ISO Code*</label>
  ) : (
    <label>State(2 Digit ISO Code)*</label>
  )
}
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="state"
                                  value={State}
                                  onChange={onChangeState}
                                  onKeyDown={(evt) => {let chck=TrimCharacters.checkAlpha(evt.key)
                                    if(chck)
                                    evt.preventDefault();
                                    }
                                     }
                                 
                                  maxLength="2"
                                />
                              </div>
                            </div>
                          </div>
                          ):(<Fragment><div id="state"></div></Fragment>)}
                          <div className="col-md-6">
                            <div className="form-group" id="zipcode">
                              <label> ZIP Code*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="address"
                                  value={DeliveryZipCode}
                                  onChange={onChangeDeliveryZipCode}
                                  onKeyDown={(evt) => {let chck=TrimCharacters.checkSpecialCharacters(evt.key)
                                    if(chck)
                                    evt.preventDefault();
                                    }
                                     }
                                  validations={[zipCodeRequired,zipCodeLength]}
                                  maxLength="10"
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div className="form-group">
                              <label>Card Fee Currency Code*</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  onChange={onChangeCardFeeCurrencyCode}
                                >
                                  <option
                                    select="selected"
                                    value={cardFeeCurrencyCode}
                                  >
                                    {cardFeeCurrencyCode}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div> */}
                          {
                            IssuerCategory!=="IssuerT" &&(
                            <div className="col-md-6">
                            <div className="form-group" id="id_cardproxy">
                              <label>Card Proxy Number*</label>
                              <div className="form-controls">
                                <Input
                                  type="number"
                                  maxLength="16"
                                  className="form-control no-icon"
                                  name="address"
                                  onInput={maxLengthCheck}
                                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                  onWheel={(e) => e.target.blur()}
                                  value={CardProxyNumber}
                                  onChange={onChangeProxyCode}
                                  validations={[proxyCodeRequired]}
                                  
                                />
                              </div>
                            </div>
                          </div>
                            )
                          }
                          
                          
                          {IssuerCategory==="IssuerG" ?(
                            <Fragment>
                            <div className="col-md-6" id="cardcolor">
                            <div className="form-group">
                              <label>Card Color*</label>
                              <div className="form-controls">
                                <select
                                  onChange={onChangeCardColor}
                                  className="form-control no-icon"
                                  id="delivery_mode"
                                 
                                >
                                <option  value="">Select Card Color</option>
                                  <option  select="selected" value='Green'>
                                    Green
                                  </option>
                                  <option   value='Silver'>
                                   Black
                                  </option>
                                  
                                </select>
                                <span
                                className={
                                 "help-block " + showCardColorErr
                               }
                                 >
                                 Card Color is required!
                                 </span>
                             
                                        </div>
                                      </div>
                          </div>
                            </Fragment>
                          ):(<Fragment><div id="cardcolor"></div></Fragment>)}
                          {
                           ( IssuerCategory==="IssuerB4B" || IssuerCategory==="IssuerT" )?(
                            <div className="col-md-6">
                            <div className="form-group" id="id_cardnumber">
                              <label>Card Number*</label>
                              <div className="form-controls">
                                <Input
                                  type="number"
                                  maxLength="16"
                                  className="form-control no-icon"
                                  name="cardnumber"
                                  onInput={maxLengthCheck}
                                  onWheel={(e) => e.target.blur()}
                                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                  value={CardNumber}
                                  onChange={onChangeCardNumber}
                                  validations={[cardNumberRequired]}
                               
                                />
                              </div>
                            </div>
                          </div>
                            ):(<div className="col-md-0" id="id_cardnumber"></div>)
                          }
                          {
                            (currentUser?.user?.ClientId===138 ||haspermissions.PhysicalCardCvv) ?(
                            <div className="col-md-6">
                            <div className="form-group" id="id_cardcvv">
                              <label>Card CVV</label>
                              <div className="form-controls">
                                <Input
                                  type="number"
                                  maxLength="5"
                                  className="form-control no-icon"
                                  name="address"
                                  onInput={maxLengthCheck}
                                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                  onWheel={(e) => e.target.blur()}
                                  value={CardCvv}
                                  onChange={onChangeCardCvv}
                                  //validations={[proxyCodeRequired]}
                                  
                                />
                              </div>
                            </div>
                          </div>
                            ):(<Fragment><div id="id_cardcvv"></div></Fragment>)
                          }
                          
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                              <a
                             
                                className="btn btn-outline mr-19"
                                data-bs-dismiss="modal"
                                onClick={props.onHide}
                              >
                                Cancel
                              </a>
                              <a  className="btn btn-large" onClick={IssueCardHolderHandler}>
                                Activate Card
                              </a>
                            </div>
                          </div>
                        </div>
                        <CheckButton
                          style={{ display: "none" }}
                          ref={checkBtn}
                        />
                      </Form>
                    </div>
                  </div>
                  <div
                    className={cardContentClassName}
                    id="cardDetails"
                    role="tabpanel"
                    aria-labelledby="cardDetails-tab"
                  >
                    <div className="row row-card-details-popup">
                      <div className="col-md-12">
                        <ul className="ul-card-details-popup ul-issue-card">
                          <li className="mb-20">
                            <div className="row">
                              <div className="col-4">
                                <label>Name</label>
                              </div>
                              <div className="col-8">
                                <span>{FirstName + "   " + LastName}</span>
                              </div>
                            </div>
                          </li>
                          <li className="mb-20">
                            <div className="row">
                              <div className="col-4">
                                <label>Email</label>
                              </div>
                              <div className="col-8">
                                <span>{Email}</span>
                              </div>
                            </div>
                          </li>
                          <li className="mb-20">
                            <div className="row">
                              <div className="col-4">
                                <label>Phone Number</label>
                              </div>
                              <div className="col-8">
                                <span>{phoneNumber}</span>
                              </div>
                            </div>
                          </li>
                          <li className="mb-20">
                            <div className="row">
                              <div className="col-4">
                                <label>Date of Birth</label>
                              </div>
                              <div className="col-8">
                                <span>
                                  <DateFormat date={dob} />
                                </span>
                              </div>
                            </div>
                          </li>
                          {/* <li className="mb-20">
                            <div className="row">
                              <div className="col-4">
                                <label>Card Expiry Date</label>
                              </div>
                              <div className="col-8">
                                <span>
                                  <DateFormat date={cardExpiry} />
                                </span>
                              </div>
                            </div>
                          </li> */}
                          <li className="mb-20">
                            <div className="row">
                              <div className="col-4">
                                <label>Available Balance</label>
                              </div>
                              <div className="col-8">
                                <span>$ 0</span>
                                {/* {availableBalance} */}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                          {/* <button
                            className="btn btn-outline mr-19"
                            onClick={goBackToCardHolderHandler}
                            disabled={disableBtns}
                          >
                            Back
                          </button> */}
                          <button
                            className="btn btn-large"
                            data-bs-dismiss="modal"
                            onClick={IssueCardDoneHandler}
                            disabled={disableBtns}
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        hideallpopups={props.onHide}
      />
    </Fragment>
  );
};
export default IssuePhysicalCardBody;
